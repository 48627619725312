import { useRef } from 'react';

import { HeadingLevelProvider } from '@/components/common/Heading/context';
import { SkipToContentButton } from '@/components/common/SkipToContentButton';
import { Footer } from '@/components/layout/Footer';
import { MainContainer } from '@/components/layout/Layout/Layout.styles';
import {
  NavigationBar,
  useGetNavigation,
} from '@/components/layout/NavigationBar';
import { SharedLayout } from '@/components/layout/SharedLayout';
import { PageCommonProvider } from '@/lib/usePageCommon';

import type { SharedLayoutProps } from '@/components/layout/SharedLayout';
import type { DynamicYieldProps } from '@/lib/dynamicYield';
import type { LayoutControls } from '@/lib/usePageCommon';
import type { SanityAudienceType } from '@/types/sanity';
import type { Navigation_exType, SiteSettings } from '@/types/shared';

interface LayoutProps extends SharedLayoutProps {
  preview?: boolean;
  siteSettings: SiteSettings;
  audienceRef?: SanityAudienceType;
  navigationCollection?: Navigation_exType[];
  layout?: LayoutControls;
  isStickyNavigation?: boolean;
  dynamicYield: DynamicYieldProps;
}

export const Layout = ({
  children,
  SEO,
  preview = false,
  siteSettings,
  localeTranslations,
  canonicalPagePath,
  audienceRef,
  navigationCollection,
  layout,
  isStickyNavigation,
  dynamicYield,
}: LayoutProps) => {
  const mainRef = useRef<HTMLElement | null>(null);

  const navigation = useGetNavigation(
    navigationCollection,
    siteSettings,
    audienceRef,
  );

  return (
    <PageCommonProvider
      siteSettings={siteSettings}
      layout={layout}
      preview={preview}
    >
      <SharedLayout
        localeTranslations={localeTranslations}
        canonicalPagePath={canonicalPagePath}
        SEO={SEO}
        dynamicYield={dynamicYield}
      >
        <HeadingLevelProvider>
          <SkipToContentButton focusElement={mainRef} />

          {navigation && (
            <NavigationBar
              navigation={navigation}
              audiences={
                siteSettings?.isAudienceSwitcherEnabled &&
                navigationCollection
                  .map((navigation_ex) => navigation_ex.audience)
                  .filter((audience) => audience !== null)
              }
              siteSettings={siteSettings}
              isSticky={isStickyNavigation}
            />
          )}

          <MainContainer ref={mainRef} tabIndex={-1}>
            {children}
          </MainContainer>

          <Footer {...siteSettings} />
        </HeadingLevelProvider>
      </SharedLayout>
    </PageCommonProvider>
  );
};
