import { renderBlocks } from '@/components/blocks';
import { Layout } from '@/components/layout/Layout';
import { BASE_REVALIDATE } from '@/lib/constants';
import {
  allSlugsFromPagesQuery,
  latestPostsQuery,
  localeTranslationsQuery,
  navigationCollectionQuery,
  pageQuery,
  siteSettingsQuery,
} from '@/lib/queries';
import { getClient, readToken } from '@/lib/sanityUtils';
import {
  buildLayoutProp,
  getSettledValue,
  getValidLocale,
  queryParametersWithAudience,
} from '@/lib/utils';

import type {
  Navigation_exType,
  PageData,
  SiteSettings,
  Translation,
} from '@/types/shared';
import type {
  GetStaticPaths,
  GetStaticProps,
  InferGetStaticPropsType,
} from 'next';

export const getStaticPaths = (async () => {
  const client = getClient();

  const allSlugs = await client.fetch(allSlugsFromPagesQuery());
  const staticSlugs = [
    'careers',
    'events',
    'impact-report-2023',
    '404',
    'employer-contact-form',
  ];

  return {
    paths: allSlugs
      .filter((page) => !staticSlugs.includes(page.slug.current))
      .map((page) => ({
        params: { slug: page.slug.current.split('/') },
        locale: page._lang,
      })),

    fallback: 'blocking',
  };
}) satisfies GetStaticPaths;

export const getStaticProps = (async ({
  params: { slug },
  locale,
  draftMode = false,
}) => {
  const validLocale = getValidLocale(locale);

  if (!validLocale) {
    return {
      notFound: true,
    };
  }
  const client = getClient(draftMode ? { token: readToken } : undefined);

  const navigationCollection = await client.fetch<Navigation_exType[]>(
    navigationCollectionQuery({ locale: validLocale }),
  );

  /*
    Dynamic routing returns undefined on the empty slug,
    so we have to parse it further to the CMS to get the data for a homepage.
   */

  slug = slug ? (slug as string[]).join('/') : '/';

  const pageData = await client.fetch<PageData>(
    pageQuery({
      ...queryParametersWithAudience(slug, navigationCollection),
      locale: validLocale,
    }),
  );

  if (!pageData) {
    return {
      notFound: true,
    };
  }

  const siteSettingsPromise = client.fetch<SiteSettings>(
    siteSettingsQuery({ locale: validLocale }),
  );

  const translationsPromise = client.fetch<Translation[]>(
    localeTranslationsQuery(pageData),
  );

  const getLatestPostsData = async () => {
    if (
      pageData?.blocks?.find(
        (block) =>
          block._type === 'latestPosts' || block._type === 'articleGrid',
      )
    ) {
      return client.fetch(latestPostsQuery({ locale: validLocale }));
    }
    return Promise.reject();
  };

  const [siteSettingsSettled, translationsSettled, latestPostsDataSettled] =
    await Promise.allSettled([
      siteSettingsPromise,
      translationsPromise,
      getLatestPostsData(),
    ]);

  return {
    props: {
      pageData,
      slug,
      locale: validLocale,
      siteSettings: getSettledValue(siteSettingsSettled),
      latestPostsData: getSettledValue(latestPostsDataSettled),
      translations: getSettledValue(translationsSettled),
      navigationCollection,
      draftMode,
      token: draftMode ? readToken : '',
    },
    revalidate: Number(process.env.REVALIDATE || BASE_REVALIDATE),
  };
}) satisfies GetStaticProps;

const Page = ({
  pageData,
  siteSettings,
  latestPostsData,

  navigationCollection,
  draftMode,
  translations,
}: InferGetStaticPropsType<typeof getStaticProps>) => {
  return (
    <Layout
      SEO={pageData?.SEO}
      localeTranslations={translations}
      canonicalPagePath={pageData?.canonicalPage?.slug.current}
      siteSettings={siteSettings}
      preview={draftMode}
      navigationCollection={navigationCollection}
      dynamicYield={pageData?.dynamicYield}
      audienceRef={pageData?.audienceRef}
      layout={buildLayoutProp(pageData)}
    >
      {renderBlocks(pageData?.blocks, {
        latestPostsData,
        pageAudience: pageData?.audienceRef,
        isAudienceSwitcherEnabled: siteSettings?.isAudienceSwitcherEnabled,
      })}
    </Layout>
  );
};

export default Page;
